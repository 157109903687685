var homeComponent = null;

function jsFunctionSetupMapy() {

  //hack because script is at the end body tag
  setTimeout(function () {
  
    var inputEl = document.querySelector("#searchplace");
    var suggest = new SMap.Suggest(inputEl);
    suggest.urlParams({
        // omezeni pro celou CR (Only Czech Republic)
        bounds: "48.5370786,12.0921668|51.0746358,18.8927040"
    });
    suggest.addListener("suggest", function(suggestData) {
      // vyber polozky z naseptavace
      homeComponent.selectPlaceData(suggestData);
    }).addListener("close", function() {
    });

  }, 3000);

};